<template>
  <div>
    <md-table md-card table-header-color="blue" :value="leagueRewards">
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Награды лиги</h1>
        </div>
      </md-table-toolbar>

      <md-table-empty-state md-label="Список пуст" />

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Шаг">
          {{ item.stepReward }}
        </md-table-cell>

        <md-table-cell md-label="Количество">
          {{ item.count }}
        </md-table-cell>

        <md-table-cell md-label="Тип предмета">
          {{ itemTypesById[item.itemTypeId] }}
        </md-table-cell>

        <md-table-cell>
          <md-button
            class="md-just-icon md-simple md-primary"
            @click="openItemInModal(item)"
          >
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="left">Изменить</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <LeagueRewardsModal
      v-if="showModal && !!selectedItem"
      :item="selectedItem"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    LeagueRewardsModal: () => import("@/components/Modals/LeagueRewardsModal"),
  },

  data() {
    return {
      selectedItem: null,
      showModal: false,
    };
  },

  computed: {
    ...mapGetters("items", ["itemTypesById"]),
    ...mapState("leagueRewards", ["leagueRewards"]),
  },

  async mounted() {
    this.showLoader(true);
    await this.getItemTypes();
    await this.getLeagueRewards();
    this.showLoader(false);
  },

  methods: {
    ...mapActions("items", ["getItemTypes"]),
    ...mapActions("leagueRewards", ["getLeagueRewards"]),
    ...mapMutations({
      showLoader: "SET_SHOW_LOADER",
    }),

    async closeModal(needUpdate) {
      this.showModal = false;
      this.selectedItem = null;

      if (needUpdate == true) {
        this.showLoader(true);
        await this.getLeagueRewards();
        this.showLoader(false);
      }
    },

    openItemInModal(item) {
      this.selectedItem = item;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .md-table-cell-container {
    white-space: nowrap;
  }
}
</style>
